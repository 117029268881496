import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@ieCore/services/auth.service';
import { NavService } from '@ieCore/services/nav.service';
import { selectUser } from '@ieApp/store/user/user.selectors';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import logger from '@ieShared/utils/logger.utils';

export const guestGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const navService = inject(NavService);
  const router = inject(Router);
  const store = inject(Store);

  if (authService.isAuthenticated()) {
    const userType = authService.getUserTypeFromToken();
    store
      .select(selectUser)
      .pipe(filter((user) => user !== null))
      .subscribe((user) => {
        const route = navService.getRouteByUserTypeAndPermission(
          userType,
          user?.permissions,
        );

        router
          .navigate([route])
          .then(() => logger.log(`Navigated to ${userType} dashboard`));
      });

    return false;
  }

  return true;
};
