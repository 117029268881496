<div class="permission-denied flex flex-col justify-center items-center">
  <div class="display-s cl-on-surface mb-32">Permission Denied</div>
  <div class="display-s cl-on-surface">
    <ie-button
      buttonType="link"
      fullWidth
      [routerLink]="[navService.to.login | navigate]"
    >
      Back to main page</ie-button
    >
  </div>
</div>
