@if (mode === NotificationComponentTypes.SIMPLE) {
  <div class="flex justify-between items-start">
    <div class="notification-title mb-24 pl-24 pr-24">
      {{ 'NOTIFICATION.TITLE' | translate }}
    </div>
    <div
      class="pl-16 pr-16 cursor-pointer"
      (click)="close()"
      (keydown)="close()"
      [tabindex]="10"
    >
      <ie-icons icon="close"></ie-icons>
    </div>
  </div>
}

@if (allNotificationsCount === 0) {
  <div class="notifications-empty flex flex-col items-center">
    <img
      class="mb-4"
      ngSrc="/assets/icons/notification/empty-notification.svg"
      height="188"
      width="220"
      alt="No notifications"
    />
    <div class="title-m mb-16">
      {{ 'NOTIFICATION.GET_NOTIFIED_HERE' | translate }}
    </div>
    <div class="notification-desc body-b flex t-align-c">
      {{ 'NOTIFICATION.GET_NOTIFIED_HERE_DESC' | translate }}
    </div>
  </div>
} @else {
  <div class="tabs mb-24 pl-24 pr-24 flex">
    <ie-button
      class="mr-8"
      [buttonType]="status === NotificationStatus.ALL ? 'primary' : 'outline'"
      [size]="'xs'"
      (click)="statusChange(NotificationStatus.ALL)"
    >
      {{ 'NOTIFICATION.ALL' | translate }}
    </ie-button>
    <ie-button
      class="mr-8"
      [buttonType]="
        status === NotificationStatus.UNREAD ? 'primary' : 'outline'
      "
      [size]="'xs'"
      (click)="statusChange(NotificationStatus.UNREAD)"
    >
      {{ 'NOTIFICATION.UNREAD' | translate }}
    </ie-button>
    @if (mode === NotificationComponentTypes.SEE_ALL) {
      <ie-button
        class="ml-auto"
        [buttonType]="'outline'"
        [size]="'xs'"
        (click)="tap(undefined)"
      >
        {{ 'NOTIFICATION.MARK_ALL_READ' | translate }}
      </ie-button>
    }
  </div>

  @if (loading) {
    <div class="notifications-loading flex justify-center">
      <app-loading></app-loading>
    </div>
  } @else {
    <div class="notifications-container">
      <div class="flex justify-between items-center mb-8 mt-8 pl-24 pr-24">
        @if (
          mode === NotificationComponentTypes.SIMPLE && notifications.length
        ) {
          <div class="title-m">{{ 'NOTIFICATION.NEW' | translate }}</div>
          <div
            [tabindex]="200"
            class="cursor-pointer link"
            (click)="seeAll.emit()"
            (keydown)="seeAll.emit()"
          >
            {{ 'NOTIFICATION.SEE_ALL' | translate }}
          </div>
        }
      </div>
      <div class="flex flex-col">
        @for (
          notification of notifications;
          track notification.id;
          let last = $last
        ) {
          <div
            class="notification-item cursor-pointer flex pl-24 pt-16 pb-16 pr-24"
            [ngClass]="{
              'notification-last-item':
                mode === NotificationComponentTypes.SIMPLE && last,
            }"
            [tabindex]="100"
            (click)="tap(notification)"
            (keydown)="tap(notification)"
          >
            <div class="notification-icon mr-16">
              <nz-avatar nzText="AA" nzSize="large"></nz-avatar>
            </div>
            <div class="notification-text mr-16">
              <div class="notification-item-title label-m mb-4">
                {{
                  notification.sender
                    ? notification.sender.firstName
                    : notification.receiver.firstName
                }}
              </div>
              <div
                class="notification-item-text body-c mb-4"
                [innerHTML]="notification.inappMsg"
              ></div>
              <div class="notification-item-date">
                {{ notification.created | timeago }}
              </div>
            </div>
            <div class="notification-icon flex items-center">
              <div
                class="notification-status"
                [ngClass]="{
                  'notification-status--unread':
                    notification.inappStatus === InAppStatus.UNREAD,
                }"
              ></div>
            </div>
          </div>
        } @empty {
          <div class="notifications-empty p-16 flex flex-col items-center">
            <img
              class="mb-4"
              ngSrc="/assets/icons/notification/empty-notification.svg"
              height="188"
              width="220"
              alt="No notifications"
            />
            <div class="title-m mb-16">
              {{ 'NOTIFICATION.GET_NOTIFIED_HERE' | translate }}
            </div>
            <div class="notification-desc body-b flex t-align-c">
              {{ 'NOTIFICATION.GET_NOTIFIED_HERE_DESC' | translate }}
            </div>
          </div>
        }
      </div>
      @if (
        mode === NotificationComponentTypes.SEE_ALL &&
        notifications.length === 10
      ) {
        <ie-button
          class="pl-16 pr-16 mt-24 mb-4"
          buttonType="outline"
          size="sm"
          (click)="loadMoreNotifications()"
          [fullWidth]="true"
        >
          {{ 'NOTIFICATION.SEE_PREVIOUS_NOTIFICATIONS' | translate }}
        </ie-button>
      }
    </div>
  }
}
