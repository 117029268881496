import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { routes } from './app.routes';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { authInterceptor } from '@ieCore/interceptors/auth.interceptor';
import { errorInterceptor } from '@ieCore/interceptors/error.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UserEffects } from '@ieApp/store/user/user.effects';

import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { refreshTokenInterceptor } from '@ieCore/interceptors/refresh-token.interceptor';
import { appStates } from '@ieApp/store/states';
import {
  enumInitFactory,
  InitAppService,
  themeInitFactory,
  topicInitFactory,
  userInitFactory,
} from '@ieCore/services/init-app.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader';
import {
  DEFAULT_LANGUAGE,
  TRANSLATION_MODULES,
} from '@ieCore/consts/translation.consts';
import { NgxEchartsModule } from 'ngx-echarts';
import { TimeagoModule } from 'ngx-timeago';
import { cashVersion } from '@ieRoot/version-manager/version-manager';

// import { slowInternetInterceptor } from '@ieCore/interceptors/slow-internet.interceptor';

export function moduleHttpLoaderFactory(http: HttpClient) {
  TRANSLATION_MODULES.version = cashVersion;
  return new ModuleTranslateLoader(http, TRANSLATION_MODULES);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptors([
        refreshTokenInterceptor,
        authInterceptor,
        // slowInternetInterceptor,
        errorInterceptor,
      ]),
    ),
    provideNzI18n(en_US),
    provideRouter(routes, withComponentInputBinding()),
    provideAnimations(),
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: DEFAULT_LANGUAGE,
        loader: {
          provide: TranslateLoader,
          useFactory: moduleHttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      NgxEchartsModule.forRoot({
        /**
         * This will import all modules from echarts.
         * If you only need custom modules,
         * please refer to [Custom Build] section.
         */
        echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
      }),
      TimeagoModule.forRoot({}),
    ]),
    {
      provide: APP_INITIALIZER,
      useFactory: userInitFactory,
      deps: [InitAppService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: topicInitFactory,
      deps: [InitAppService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: themeInitFactory,
      deps: [InitAppService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: enumInitFactory,
      deps: [InitAppService],
      multi: true,
    },
    provideStore({}),
    provideEffects([UserEffects]),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    ...appStates,
  ],
};
