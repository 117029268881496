import { Injectable } from '@angular/core';
import * as UserActions from '@ieApp/store/user/user.actions';
import { filter, firstValueFrom } from 'rxjs';
import { selectUser, selectUserError } from '@ieApp/store/user/user.selectors';
import { take } from 'rxjs/operators';
import { AuthService } from '@ieCore/services/auth.service';
import { Store } from '@ngrx/store';
import { ThemeService } from '@ieCore/services/theme.service';
import { TopicService } from '@ieCore/services/topic.service';
import { Permission } from 'shared-general-libs/enum/permission';
import { OrganizationService } from '@ieCore/services/organization.service';
import { requestLoading } from '@ieCore/helpers/request-loading.helper';

@Injectable({
  providedIn: 'root',
})
export class InitAppService {
  getEnumList = requestLoading<{ [p: string]: string }>();

  constructor(
    private authService: AuthService,
    private topicService: TopicService,
    private themeService: ThemeService,
    private organizationService: OrganizationService,
    private store: Store,
  ) {}

  async initUserStore(): Promise<void | boolean> {
    const authData = this.authService.getAuthData();

    if (authData.accessToken) {
      this.store.dispatch(UserActions.load());

      const result = await Promise.race([
        firstValueFrom(
          this.store.select(selectUserError).pipe(
            filter((error) => error !== null),
            take(1),
          ),
        ),
        firstValueFrom(
          this.store.select(selectUser).pipe(
            filter((userState) => userState !== null),
            take(1),
          ),
        ),
      ]);

      if (result && result.error) {
        this.authService.removeAuthData();
        window.location.reload();
      }

      return Promise.resolve();
    }

    return Promise.resolve();
  }

  async initTopicStore(): Promise<void> {
    const authData = this.authService.getAuthData();

    if (authData.accessToken) {
      const userData = await firstValueFrom(
        this.store.select(selectUser).pipe(
          filter((userState) => userState !== null),
          take(1),
        ),
      );

      if (userData!.permissions!.includes(Permission.Topic.VIEW)) {
        await this.topicService.storeTopics();
      }

      return Promise.resolve();
    }

    return Promise.resolve();
  }

  async initEnumStore(): Promise<void> {
    const authData = this.authService.getAuthData();

    if (authData.accessToken) {
      this.getEnumList = this.organizationService.getEnumTranslationList();
      this.getEnumList.response.subscribe((d) => {
        this.organizationService.enumTranslationList = d;
      });
    }
  }

  initTheme(): void {
    const theme = this.themeService.getCurrentTheme();
    if (theme) {
      this.themeService.toggleTheme(theme);
    }
  }
}

export function userInitFactory(initAppService: InitAppService) {
  return () => initAppService.initUserStore();
}

export function topicInitFactory(initAppService: InitAppService) {
  return () => initAppService.initTopicStore();
}

export function themeInitFactory(initAppService: InitAppService) {
  return () => initAppService.initTheme();
}

export function enumInitFactory(initAppService: InitAppService) {
  return () => initAppService.initEnumStore();
}
