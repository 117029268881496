import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@ieCore/services/auth.service';
import { inject } from '@angular/core';
import { NavService } from '@ieCore/services/nav.service';
import logger from '@ieShared/utils/logger.utils';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const navService = inject(NavService);
  const router = inject(Router);

  if (!authService.isAuthenticated()) {
    router
      .navigate(navService.to.login())
      .then(() => logger.log('Navigated to login page'));

    return false;
  }

  return true;
};
