<div class="main-holder">
  <div class="child-holder">
    <div class="materiality-survey-container">
      <div class="top-container">
        <div class="logo">
          <img ngSrc="assets/logo-dark-text.svg" alt="" height="33" width="268">
        </div>
        @if (surveyStatus === SurveyAnswerState.NEW) {
          <div class="header mx24">
            {{
              (type === 'stakeholders' ? "MATERIALITY.MATERIALITY_SURVEY_IMPACT_ON_STAKEHOLDERS"
                : "MATERIALITY.MATERIALITY_SURVEY_IMPACT_ON_BUSINESS") | translate
            }}
          </div>
          <div class="description mt24 mx24">
            {{ 'MATERIALITY.WE_ARE_SEEKING_TOUR_INSIGHTS' | translate }}
          </div>
          <div class="information-block mt24 py8">
            <span class="mx24">
            {{ 'MATERIALITY.PLEASE_RATE_THE_FOLLOWING_TOPICS' | translate }}
            </span>
          </div>
        }
      </div>
      <div class="middle-container">
        @if (surveyData?.questionary?.length && surveyStatus === SurveyAnswerState.NEW) {
          @for (item of surveyData?.questionary; track item.topicHint) {
            <div class="card">
              <div class="header">{{ item.topicHint }}</div>
              <div class="description">{{ item.question }}</div>
              <div class="rating-container">
                <div class="rate-ruler">
                  <div class="container">
                    @for (i of numbers; track i) {
                      <div class="rate"
                           (click)="selectRate(item.topic, i)"
                           (keydown)="selectRate(item.topic, i)"
                           [tabindex]="101"
                           [ngClass]="{
                         'start-actives': i >= 1 && i < 4 && responseData.get(item.topic)?.value === i,
                         'middle-actives': i >= 4 && i < 8 && responseData.get(item.topic)?.value === i,
                         'end-actives': i >= 8 && i <= 10 && responseData.get(item.topic)?.value === i
                         }">{{ i }}
                      </div>
                    }
                  </div>
                </div>
                <div class="fz-11 flex justify-between rate-indicator">
                  <div>{{ 'MATERIALITY.LOW_IMPACT' | translate }}</div>
                  <div>{{ 'MATERIALITY.HIGH_IMPACT' | translate }}</div>
                </div>
              </div>
            </div>
          }
        } @else if (surveyStatus === SurveyAnswerState.ENDED) {
          <div class="survey-completed">
            <div>
              <img ngSrc="assets/icons/survey/incomplete.svg" alt="" height="80" width="80">
            </div>
            <div class="srv-txt mt24">
              {{ 'MATERIALITY.SURVEY_HAS_ENDED' | translate }}
            </div>
            <div class="srv-description text-color-light">
              {{ 'MATERIALITY.THE_SURVEY_PERIOD_HAS_CLOSED' | translate }}
            </div>
          </div>
        } @else if (surveyStatus === SurveyAnswerState.COMPLETE) {
          <div class="survey-completed">
            <div>
              <img ngSrc="assets/icons/survey/success.svg" alt="" height="80" width="80">
            </div>
            <div class="srv-txt mt24">
              {{ 'MATERIALITY.SUCCESSFULLY_COMPLETED' | translate }}
            </div>
            <div class="text-color-light">
              {{ 'MATERIALITY.THE_PROCESS_COMPLETE_SUCCESSFULLY' | translate }}
            </div>
          </div>
        }
      </div>
      <div class="bottom-container">
        @if (surveyStatus === SurveyAnswerState.NEW) {
          <ie-button
            (click)="saveData()"
            [disabled]="responseData.size !== surveyData?.questionary?.length"
            buttonType="primary"
            size="sm"
          >{{ 'MATERIALITY.COMPLETE' | translate }}
          </ie-button>
        } @else {
          <ie-button
            routerLink="/"
            buttonType="primary"
            size="sm"
          >{{ 'CLOSE' | translate }}
          </ie-button>
        }
      </div>
    </div>
  </div>
</div>
