import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@ieCore/services/auth.service';
import { UserType } from 'shared-general-libs/type/user';
import { NavService } from '@ieCore/services/nav.service';
import logger from '@ieShared/utils/logger.utils';

export const certifierGuard: CanActivateFn = () => {
  const navService = inject(NavService);
  const router = inject(Router);

  const authService = inject(AuthService);
  if (authService.getUserTypeFromToken() === UserType.CERTIFIER_USER) {
    return true;
  }

  router
    .navigate([navService.to.notFound()])
    .then(() => logger.log('Navigated to not found page'));

  return false;
};
