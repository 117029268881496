import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeMode } from '@ieCore/enums/theme';
import { IeButtonComponent } from '@ieShared/components/ie-button/ie-button.component';
import { NotificationResponseDto } from 'shared-general-libs/dto/notification';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { IeAlertComponent } from '@ieShared/components/ie-alert/ie-alert.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationComponent } from '@ieShared/pages/notification/notification.component';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';
import { TimeagoModule } from 'ngx-timeago';
import { InAppStatus } from 'shared-general-libs/type/notification';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { IeIconsComponent } from '@ieShared/components/ie-icons/ie-icons.component';
import {
  NotificationComponentTypes,
  NotificationStatus,
} from '@ieShared/interfaces/notifications.interface';
import { LoadingComponent } from '@ieShared/components/loading/loading.component';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    IeButtonComponent,
    IeAlertComponent,
    TranslateModule,
    NgOptimizedImage,
    NotificationComponent,
    NzAvatarComponent,
    TimeagoModule,
    NgClass,
    NzSpinComponent,
    IeIconsComponent,
    LoadingComponent,
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent {
  public readonly NotificationComponentTypes = NotificationComponentTypes;
  @Input() mode: NotificationComponentTypes = NotificationComponentTypes.SIMPLE;
  @Input() notifications: NotificationResponseDto[] = [];
  @Input() allNotificationsCount = 0;
  @Input() loading: boolean = false;
  @Output() statusChanged = new EventEmitter<NotificationStatus>();
  @Output() seeAll = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();
  @Output() loadMore = new EventEmitter<void>();
  @Output() markAsRead = new EventEmitter<
    NotificationResponseDto | undefined
  >();

  InAppStatus = InAppStatus;
  NotificationStatus = NotificationStatus;
  status: NotificationStatus = NotificationStatus.ALL;

  protected readonly ThemeMode = ThemeMode;
  constructor() {}

  statusChange(status: NotificationStatus) {
    this.status = status;
    this.statusChanged.emit(status);
  }

  loadMoreNotifications() {
    this.loadMore.emit();
  }

  close() {
    this.closeModal.next();
  }

  tap(notification: NotificationResponseDto | undefined): void {
    this.markAsRead.next(notification);
  }
}
