import { Component } from '@angular/core';
import { IeButtonComponent } from '@ieShared/components/ie-button/ie-button.component';
import { NavigatePipe } from '@ieShared/pipes/navigate.pipe';
import { NavService } from '@ieCore/services/nav.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-permission-denied',
  standalone: true,
  imports: [IeButtonComponent, NavigatePipe, RouterLink],
  templateUrl: './permission-denied.component.html',
  styleUrl: './permission-denied.component.scss',
})
export class PermissionDeniedComponent {
  constructor(public navService: NavService) {}
}
