import { PaginationInterface } from '@ieShared/interfaces/pagination.interface';

export interface NotificationRequestParams extends PaginationInterface {
  inappStatus?: string;
}

export enum NotificationComponentTypes {
  SIMPLE = 'SIMPLE',
  SEE_ALL = 'SEE_ALL',
}

export enum NotificationStatus {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
}
