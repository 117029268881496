import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map } from 'rxjs/operators';
import { mergeMap, of } from 'rxjs';
import * as UserActions from './user.actions';
import { UserService } from '@ieCore/services/user.service';

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.load),
      mergeMap(() =>
        this.userService.getMe().response.pipe(
          map((user) => UserActions.loadSuccess({ user })),
          catchError((error) => of(UserActions.loadFailure({ error }))),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private userService: UserService,
  ) {}
}
